<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <div class="d-flex justify-end ma-3 bg-surface-variant">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="blue-grey">
          <strong class="mr-1 ml-1 mt-2 pink--text text--lighten-4"
            >ตั้งเวลายิง</strong
          >
          <span class="mr-1 ml-1 mt-2">นาที :</span>
          <v-text-field
            v-model="ShootEndTime.MM"
            class="txtShootMM"
            ref="txtShootMM"
            color="white"
            @input="OnTextChange_txtShootMMEndTime"
            type="number"
            outlined
            hide-details
            dense
            style="width: 55px;"
          ></v-text-field>
          <span class="mr-1 ml-1 mt-2">วินาที : </span>
          <v-text-field
            v-model="ShootEndTime.SS"
            class="txtShootSS"
            ref="txtShootSS"
            color="white"
            @input="OnTextChange_txtShootSSEndTime"
            type="number"
            outlined
            hide-details
            dense
            style="width: 55px;"
          ></v-text-field>
          <span class="mr-1 ml-1 mt-2">ms : </span>
          <v-text-field
            v-model="ShootEndTime.MS"
            class="txtShootMS"
            ref="txtShootMS"
            color="white"
            @input="OnTextChange_txtShootMSEndTime"
            type="number"
            outlined
            hide-details
            dense
            style="width: 55px;"
          ></v-text-field>
          <v-btn
            color="orange darken-3"
            class="ml-3 mt-0 mb-0"
            style="height: 40px;"
            @click="submitData"
            >ตกลง</v-btn
          >
          <v-btn color="red" class="ml-3 mt-0 mb-0" style="height: 40px;"
            >ตั้งเท่ากันทุกเครื่อง</v-btn
          >
        </v-row>
      </v-form>
    </div>
    <v-data-table
      :headers="headers"
      :items="machine_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->

      <template v-slot:top>
        <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม
          <strong>{{ row_count }}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'pink darken-4': item.ID === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column">
            <v-layout justify-center>{{ item.MachineName }}</v-layout>
          </td>
          <td>
            <v-layout justify-center>{{ item.ShootEndTime }}</v-layout>
          </td>
          <td>
            <v-layout justify-center>{{ item.Roomname }}</v-layout>
          </td>
          <td>
            <v-layout justify-center>{{ item.Round }}</v-layout>
          </td>
          <td>
            <v-layout justify-center>{{ item.ActiveDT }}</v-layout>
          </td>
          <td>
            <v-layout justify-center>{{
              Permission[item.Permission]
            }}</v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/SetTime/Popup_Menu";

export default {
  name: "Machine_Datatable",

  components: {
    PopupMenu,
  },
  created() {
    //โหลด ตาราง
    this.fetch_time_setting();
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    machine_data() {
      //console.log("gettes - - >" + this.$store.getters["id/IDList"]);
      return this.$store.getters["machine/MachineList"];
    },
    row_count() {
      return this.$store.getters["machine/MachineList"].length;
    },
  },
  methods: {
    async fetch_time_setting() {
      let credentials = {
        web_name: sessionStorage.getItem("WebName"),
        WebName: this.WebName,
        Roomname: this.Roomname,
      };
      this.$store.dispatch("web/get_room_detail", credentials).then(
        (response) => {
          // console.log(response.RoomData.ShootEndTime);
          const arrShootEndTime = response.RoomData.ShootEndTime.split(":");
          // console.log(arrShootEndTime);
          this.ShootEndTime.MM = parseInt(arrShootEndTime[0]);
          this.ShootEndTime.SS = parseInt(arrShootEndTime[1]);
          this.ShootEndTime.MS = parseInt(arrShootEndTime[2]);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async fetch_table() {
      let credentials = {
        web_name: sessionStorage.getItem("WebName"),
      };
      this.$store.dispatch("machine/load_machine_list", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    submitData() {
      if (this.submitted == false) this.OnChangeTime();
    },

    async OnChangeTime() {
      this.submitted = true;

      let credentials = {
        WebName: this.WebName,
        Roomname: this.Roomname,
        Method: "update_time",
        ShootEndTime:
          this.ShootEndTime.MM.toString().padStart(2, "0") +
          ":" +
          this.ShootEndTime.SS.toString().padStart(2, "0") +
          ":" +
          this.ShootEndTime.MS.toString().padStart(3, "0"),
      };

      await this.$store.dispatch("web/set_room_detail", credentials).then(
        (response) => {
          //   console.log(response.RoomData.Status);
          this.$alert("", "บันทึกข้อมูลเรียบร้อย", "success");
          this.submitted = false;
          // this.$emit("Next");
        },
        (error) => {
          //   this.login_message(error);
          console.log(error);
          // switch (
          //   error
          //   // case "ID_DUPLICATE":
          //   //   this.error_text = "xxx";
          //   //   break;
          // ) {
          // }
          this.submitted = false;
        }
      );
    },

    OnTextChange_txtShootMMEndTime() {
      if (parseFloat(this.ShootEndTime.MM) > 59) {
        this.$nextTick(() => {
          this.ShootEndTime.MM = 59;
        });
      }
    },

    OnTextChange_txtShootSSEndTime() {
      if (parseFloat(this.ShootEndTime.SS) > 59) {
        this.$nextTick(() => {
          this.ShootEndTime.SS = 59;
        });
      }
    },

    OnTextChange_txtShootMSEndTime() {
      if (parseFloat(this.ShootEndTime.MS) > 999) {
        this.$nextTick(() => {
          this.ShootEndTime.MS = 999;
        });
      } else if (parseFloat(this.ShootEndTime.MS) < 0) {
        this.$nextTick(() => {
          this.ShootEndTime.MS = 0;
        });
      }
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selectedId = item.MachineName;
      console.log(item.id);
    },
    row_click: function(item) {
      this.selectedId = item.MachineName;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();

      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.MachineName);
        this.selectedId = item.MachineName;
        //console.log(item);
      });
    },
  },
  data: () => ({
    selectedId: -1,

    submitted: false,

    valid: true,
    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,
    WebName: sessionStorage.getItem("WebName"),
    Roomname: sessionStorage.getItem("Roomname"),

    ShootEndTime: { MM: 0, SS: 0, MS: 0 },

    headers: [
      {
        text: "ชื่อเครื่อง",
        value: "ID",
        align: "center",
        width: "150px",
      },
      {
        text: "เวลายิง",
        value: "ShootEndTime",
        align: "center",
        width: "80px",
      },
      { text: "ห้องล่าสุด", value: "Credit", align: "center", width: "60px" },
      { text: "รอบล่าสุด", value: "Credit", align: "center", width: "60px" },
      {
        text: "เชื่อมต่อล่าสุด",
        value: "Credit",
        align: "center",
        width: "60px",
      },
      { text: "สถานะ", value: "Status", align: "center", width: "50px" },
    ],
    Permission: ["รอยืนยัน", "ใช้งาน", "ปฏิเสธให้เข้าใช้", "หยุดแทง"],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: #880e4f !important;
  color: white;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #880e4f !important;
  color: white !important;
  padding-top: 15px !important;
  vertical-align: top;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  vertical-align: center;
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  border-right: 1px solid;
  border-top: 1px solid;
  border-color: rgb(51, 51, 51);
  /* font-size: 110% !important; */
}

::v-deep table tbody tr td {
  /* font-size: 1.5vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  /* font-size: 110% !important; */
  border-right: 1px solid;
  border-color: rgb(51, 51, 51);
}

tbody tr:nth-of-type(even) {
  background-color: rgb(0, 0, 0);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(19, 19, 19);
}

::v-deep .txtShootMM input[type="number"],
::v-deep .txtShootSS input[type="number"],
::v-deep .txtShootMS input[type="number"],
::v-deep .txtShootMM input::-webkit-outer-spin-button,
::v-deep .txtShootMM input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep .txtShootSS input::-webkit-outer-spin-button,
::v-deep .txtShootSS input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::v-deep .txtShootMS input::-webkit-outer-spin-button,
::v-deep .txtShootMS input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
