<template>
  <v-container>
    <v-app-bar fixed app color="blue-grey darken-3" dense dark>
      <v-btn icon to="/Manage">
        <v-icon large>mdi-arrow-left-thin-circle-outline</v-icon>
      </v-btn>
      <v-toolbar-title>ตั้งเวลาบอท</v-toolbar-title>
      <v-spacer></v-spacer>
      {{ WebName }} {{ Roomname }}
    </v-app-bar>
    <Table />
  </v-container>
</template>

<script>
import Table from "@/components/SetTime/Table";

export default {
    name: "Time_Setting",

    components: {
        Table,
    },
    data: () => ({
    a: "",
    WebName: sessionStorage.getItem("WebName"),
    Roomname: sessionStorage.getItem("Roomname"),
  }),

};
</script>

<style></style>
