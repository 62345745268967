<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list dense>
        <v-list-item link @click="menuActionClick('ChangeStatus')">
          <v-list-item-icon>
            <v-icon color="yellow">mdi-traffic-light-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>การอนุญาติ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('MaxScreen')">
          <v-list-item-icon>
            <v-icon color="blue">mdi-monitor</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>จอสูงสุด</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item link @click="menuActionClick('delete')">
          <v-list-item-icon>
            <v-icon color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <Permission ref="permission" :permission_dialog.sync="permission_dialog" />
    <Monitor ref="monitor" :monitor_dialog.sync="monitor_dialog" />
  </div>
</template>

<script>
import Permission from "@/components/Machine/Permission";
import Monitor from "@/components/Machine/Monitor";

export default {
  name: "PopupMenu",

  components: {
    Permission,
    Monitor,
  },

  methods: {
    Show(id) {
      this.showMenu = true;
      this.ID = id;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        case "ChangeStatus":
          this.$refs.permission.set_id(this.ID);
          this.permission_dialog = true;
          break;
        case "MaxScreen":
          this.$refs.monitor.set_id(this.ID);
          this.monitor_dialog = true;
          break;
      }
    },
  },
  data: () => ({
    ID: null,
    showMenu: false,
    x: 0,
    y: 0,

    monitor_dialog: false,
    permission_dialog: false,
  }),
};
</script>

<style></style>
